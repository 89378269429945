import React from "react";

function Family() {
    return (
        <section id="family" className="family">
            <h2>My Family</h2>
            <p>
                When I’m not breaking new ground in cloud security or perfecting embedded 
                device prototypes, I’m out on adventures with my family. I’m a dedicated 
                husband and father of three amazing kids. Together, we explore the outdoors, 
                build creative projects, and believe in making memories that last a lifetime.
            </p>
            <p>
                Around here, we see technology as a powerful tool rather than just a toy—
                although we do have a lot of fun with it! Whether we’re pitching a tent for a 
                weekend camping trip or experimenting with DIY robotics in the garage, our 
                family adventures keep me inspired to push boundaries—both in technology and in life.
            </p>
        </section>
    );
}

export default Family;