import React from "react";

function Header() {
    return (
        <header className="header">
            <h1>Christopher Smith, PhD (ABD)</h1>
            {/* Optional mini-tagline */}
            <h2 style={{ marginTop: "1rem", fontSize: "1.25rem", fontWeight: "normal" }}>
                Embarking on a cyber and hardware adventure
            </h2>
            <nav>
                <a href="#about">About</a>
                <a href="#experience">Experience</a>
                <a href="#family">Family</a>
            </nav>
        </header>
    );
}

export default Header;