import React from "react";

function Experience() {
    return (
        <section id="experience" className="experience">
            <h2>Professional Experience</h2>
            <ul>
                <li>
                    <strong>Cloud Content Developer, Cloud Range (Nov 2024 - Present)</strong>
                    <p>
                        Engineer immersive cybersecurity training environments replicating complex, 
                        real-world cloud scenarios. Focus on Zero Trust principles, advanced threat 
                        modeling, embedded system security, and secure systems design to equip teams 
                        with critical, hands-on skills in a rapidly evolving cyber landscape.
                    </p>
                </li>
                <li>
                    <strong>Graduate Fellow & Team Lead – LTRI / Air Force Global Strike Command (AFGSC)</strong>
                    <p>
                        Led a team of 5 engineers, creating new methods for synchronizing and 
                        integrating discrete simulators into air-gapped military systems. Innovated 
                        in fully homomorphic encryption and zero-trust architectures to secure 
                        vital networks from evolving threats. Included hardware integration for 
                        simulation frameworks, ensuring robust testing of embedded devices.
                    </p>
                </li>
                <li>
                    <strong>Graduate Research Assistant – Louisiana Tech University</strong>
                    <p>
                        Conducted IoT-driven research, spearheaded real-time data collection 
                        networks, and mentored undergraduate students in cyber experiments 
                        backed by Google’s CREWE Workshop. Enhanced campus infrastructure 
                        with sensor-based mesh networks and custom embedded systems.
                    </p>
                </li>
                <li>
                    <strong>TechX Lab Technician – General Dynamics Information Technology</strong>
                    <p>
                        Developed custom botnets for vulnerability research, built on-demand 
                        cyber practice ranges, and pioneered AI-based neural network projects 
                        for advanced threat detection. Also explored 3D printing prototypes and 
                        implemented embedded systems for satellite signal simulations.
                    </p>
                </li>
                <li>
                    <strong>Associate Consultant – Fenway Group</strong>
                    <p>
                        Created geo-based web apps for secure patient prescription management and 
                        optimized data solutions for Fortune 1000 clients. Collaborated on hardware 
                        integration proof-of-concepts to streamline remote data processing and 
                        visualization in healthcare settings.
                    </p>
                </li>
            </ul>
        </section>
    );
}

export default Experience;