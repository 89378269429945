import React from "react";

function Footer() {
    return (
        <footer className="footer">
            <p>Let’s Connect &amp; Explore:</p>
            <ul>
                <li>
                    <a href="mailto:christopher@smithstation.io">
                        Email
                    </a>
                </li>
                <li>
                    <a
                        href="https://linkedin.com/in/christopher-smith-phd"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        LinkedIn
                    </a>
                </li>
            </ul>
            <p>&copy; 2025 Christopher Smith</p>
        </footer>
    );
}

export default Footer;