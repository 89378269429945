import React from "react";

function About() {
    return (
        <section id="about" className="about">
            <h2>About Me</h2>
            <p>
                Hey there! I’m Christopher Smith, a Cloud Content Developer at Cloud Range and 
                a PhD candidate (ABD) in Cyberspace Engineering at Louisiana Tech University, supervised by{" "}
                <a
                    href="https://www.latech.edu/faculty-staff/single-entry/name/pedro-derosa/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Dr. Pedro Derosa
                </a>. I thrive on designing 
                immersive, zero-trust cybersecurity environments and forging new paths in 
                secure cloud architecture.
            </p>

            <p>
                With over a decade of software development experience, I’ve led projects 
                combining big data, AI, and automation to create resilient, defense-grade systems.
                I’ve also deeply researched <strong>embedded systems security</strong> and 
                <strong> wireless mesh networks</strong>, and I even build <strong>embedded 
                systems projects at home</strong>. From tinkering with sensors and 
                experimenting with 3D printing, to integrating satellite communications (SatCom) 
                for remote data collection, I’m always pushing the boundaries of what’s possible.
            </p>

            <p>
                Whether I’m tackling quantum-resistant encryption or constructing real-world 
                cyber simulations, my approach is equal parts curiosity and commitment. I see 
                technology not just as a tool, but as a gateway to new frontiers—on Earth 
                and beyond.
            </p>
        </section>
    );
}

export default About;